import React from 'react';
import { language } from '../assets/lang';
import { functionsEU } from '../components/firebase/firebase';
import Payment from './payment';
import Transactions from './transactions';
import Share from './share';
import Messages from '../assets/images/messages.svg';
import Timetable from '../assets/images/timetable.svg';
import Map from '../assets/images/map.svg';
import Lockers from '../assets/images/lockersale.svg';

class EventContainer extends React.Component {
    render() {	
        return ( 	
        <div style={{width: '100%', height: '100%', paddingBottom: 30}}>				
            
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 175, overflow: 'hidden'}}>
                <img src={this.props.eventData.cover} style={{minWidth: '100%', objectFit: 'contain'}} /> 
                {!this.searchParams.has("evt") ? 
                <div className='backbutton' onClick={() => this.props.getEvents()}>
                    <i class="fa fa-chevron-left" style={{color: 'rgba(0,0,0,0.6)'}}></i>
				</div>
                :null}
            </div>

            <div style={{display: 'flex', position: 'absolute', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', top: 162, left: 0, paddingLeft: 10, paddingRight: 10, height: 24, borderTopRightRadius: 7, borderBottomRightRadius: 7, backgroundColor: '#b32e5d'}}>
                <p style={{fontSize: 11, color: 'rgba(255,255,255,0.9)', marginLeft: 3, marginTop: 2, fontFamily: 'Kentledge'}}> {this.formatDate(this.props.eventData.tijd, this.props.eventData.type, this.props.eventData.dagen)} </p>
            </div>	

            {this.state.eventState === '' ? 
            <div style={{paddingLeft: 15, paddingRight: 15}}>
                <div style={{paddingTop: 15, paddingBottom: 20, marginTop: 12}}>
                    <p style={{fontSize: 20, color: '#22293b', fontFamily: 'Kentledge-Bold'}}>{this.props.eventData.naam}</p>
                    <p style={{fontSize: 14, color: '#22293b', fontFamily: 'Kentledge', marginTop: 3}}>{this.props.eventData.locatie}</p>
                </div>
                <div className='saldo' style={{backgroundColor: '#b32e5d'}}>
                    <div style={{justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <p style={{fontSize: 30, color: '#f1f2f4', fontFamily: 'Kentledge-bold'}}>{this.state.userData.tokens ? this.state.userData.tokens : 0}</p>
                            <p style={{fontSize: 22, marginLeft: 7, color: 'rgba(255,255,255,0.8)', fontFamily: 'Kentledge', marginTop:1}}>{this.state.userData.tokens === 1 ? language.token : language.tokens}</p>
                        </div>
                        {this.state.userData.tokens < -0.5 ?
                        <p style={{fontSize: 12, marginTop: 7, color: 'rgba(255,255,255,0.55)', width: 200, fontFamily: 'Kentledge'}}>{language.negativebalancesoonpay}.</p>
                        :
                        <p style={{fontSize: 14, marginTop: 7, color: 'rgba(255,255,255,0.55)', fontFamily: 'Kentledge'}}>{this.state.updating === 'updating' ? language.updatingbalance :null}{this.state.updating === 'updated' ? language.balanceupdated :null}{this.state.updating === 'offline' ? language.noconnection :null}</p>
                        }
                    </div>
                    <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <div onClick={() => this.setState({eventState: 'payment'})} style={{display: 'flex', flexDirection: 'row', backgroundColor: '#f1f2f4', paddingLeft: 17, paddingRight: 17, alignItems: 'center', justifyContent: 'space-between', height: 36, width: 182, borderRadius: 20}}>
                            <p style={{fontSize: 12.5, color: '#b32e5d', fontFamily: 'Kentledge'}}>{language.topupbalance}</p>
                            <i class="fa fa-chevron-right" style={{color: '#b32e5d'}}></i>
                        </div>
                    </div>
                    <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 25}}>
                        <div onClick={() => this.setState({eventState: 'share'})} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <i class="fa fa-share" style={{color: 'rgba(255,255,255,0.85)'}}></i>
                            <p style={{fontSize: 12.5, marginLeft: 10, color: 'rgba(255,255,255,0.85)', fontFamily: 'Kentledge'}}>{language.sharetokens}</p>
                        </div>
                        <div onClick={() => this.setState({eventState: 'transactions'})} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <i class="fa fa-grip-lines" style={{color: 'rgba(255,255,255,0.85)'}}></i>
                            <p style={{fontSize: 12.5, marginLeft: 10, color: 'rgba(255,255,255,0.85)', fontFamily: 'Kentledge'}}>{language.spendings}</p>
                        </div>
                    </div>
                    <div style={{height: 15}}>	
                    </div>
                    <div onClick={() => this.getSaldo()} className={this.state.updating === "updating" ? "rotating" : ""} style={{position: 'absolute', height: 30, right: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', right: 17, top: 13, zIndex: 999, cursor: 'pointer'}}>
                        <i class="fa fa-arrows-rotate" style={{fontSize: 20, color: '#fff', opacity: 0.8}}></i>
                    </div>
                </div>

                <div className='next_container'>
                    <div style={{padding: 20, paddingBottom: 0, backgroundColor: 'rgba(0,0,0,0.02)', width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <img src={Lockers} style={{width: 200, marginBottom: -24, objectFit: 'contain'}} />
                    </div>
                    <p style={{fontSize: 17, color: '#22293b', marginTop: 45, fontFamily: 'Kentledge-bold', textAlign: 'center'}} numberOfLines={1}>{language.lockers}</p>
                    <p style={{fontSize: 13, color: '#22293b', marginTop: 5, fontFamily: 'Kentledge', width: 220, textAlign: 'center'}}>{language.lockerdesc}</p>               
                    <div onClick={() => this.openLockers()} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <div style={{display: 'flex', flexDirection: 'row', backgroundColor: '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'space-between', height: 36, width: 140, borderRadius: 20}}>
                            <p style={{fontSize: 12.5, color: '#fff', fontFamily: 'Kentledge'}}>{language.openlockers}</p>
                            <i class="fa fa-chevron-right" style={{color: '#fff'}}></i>
                        </div>
                    </div>
                </div>

                <div className='next_container'>
                    <div style={{padding: 20, paddingBottom: 0, backgroundColor: 'rgba(0,0,0,0.02)', width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <img src={Map} style={{width: 200, marginBottom: -22, objectFit: 'contain'}} />
                    </div>
                    <p style={{fontSize: 17, color: '#22293b', marginTop: 45, fontFamily: 'Kentledge-bold', textAlign: 'center'}} numberOfLines={1}>{language.festivalmap}</p>
                    <p style={{fontSize: 13, color: '#22293b', marginTop: 5, fontFamily: 'Kentledge', width: 220, textAlign: 'center'}}>{language.festivalmapdesc}</p>               
                    <div onClick={() => this.openFestivalmap()} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <div style={{display: 'flex', flexDirection: 'row', backgroundColor: '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'space-between', height: 36, width: 140, borderRadius: 20}}>
                            <p style={{fontSize: 12.5, color: '#fff', fontFamily: 'Kentledge'}}>Open {language.festivalmap}</p>
                            <i class="fa fa-chevron-right" style={{color: '#fff'}}></i>
                        </div>
                    </div>
                </div>

                <div className='next_container'>
                    <div style={{padding: 20, paddingBottom: 0, backgroundColor: 'rgba(0,0,0,0.02)', width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <img src={Timetable} style={{width: 200, marginBottom: -24, objectFit: 'contain'}} />
                    </div>
                    <p style={{fontSize: 17, color: '#22293b', marginTop: 45, fontFamily: 'Kentledge-bold', textAlign: 'center'}} numberOfLines={1}>{language.timetable}</p>
                    <p style={{fontSize: 13, color: '#22293b', marginTop: 5, fontFamily: 'Kentledge', width: 220, textAlign: 'center'}}>{language.timetabledesc}</p>               
                    <div onClick={() => this.openTimetable()} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <div style={{display: 'flex', flexDirection: 'row', backgroundColor: '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'space-between', height: 36, width: 140, borderRadius: 20}}>
                            <p style={{fontSize: 12.5, color: '#fff', fontFamily: 'Kentledge'}}>{language.opentimetable}</p>
                            <i class="fa fa-chevron-right" style={{color: '#fff'}}></i>
                        </div>
                    </div>
                </div>

                <div className='next_container'>
                    <div style={{padding: 20, paddingBottom: 0, backgroundColor: 'rgba(0,0,0,0.03)', width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <img src={Messages} style={{width: 200, marginBottom: -22, objectFit: 'contain'}} />
                    </div>
                    <p style={{fontSize: 17, color: '#22293b', marginTop: 45, fontFamily: 'Kentledge-bold', textAlign: 'center'}} numberOfLines={1}>{language.needhelp}</p>
                    <p style={{fontSize: 13, color: '#22293b', marginTop: 5, fontFamily: 'Kentledge', width: 220, textAlign: 'center'}}>{language.chatwithsupport}</p>               
                    <div onClick={() => this.openWhatsapp()} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <div style={{display: 'flex', flexDirection: 'row', backgroundColor: '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'space-between', height: 36, width: 140, borderRadius: 20}}>
                            <p style={{fontSize: 12.5, color: '#fff', fontFamily: 'Kentledge'}}>{language.openwhatsapp}</p>
                            <i class="fa fa-chevron-right" style={{color: '#fff'}}></i>
                        </div>
                    </div>
                </div>

            </div>  
            :null}       

            {this.state.eventState === 'transactions' ? <Transactions changeEventState={this.changeEventState} userData={this.state.userData} eventData={this.props.eventData} /> :null}
            {this.state.eventState === 'payment' ? <Payment changeEventState={this.changeEventState} cardID={this.props.cardID} eventData={this.props.eventData} /> :null}	
            {this.state.eventState === 'share' ? <Share changeEventState={this.changeEventState} userData={this.state.userData} eventData={this.props.eventData} /> :null}	


        </div>
        )
    }

    state = {userData: '', updating: 'updated', eventState: ''}

    componentDidMount(){this.getSaldo()}
    
    getSaldo(){
        this.setState({updating: 'updating'});
        var getCardBalance = functionsEU.httpsCallable('getCardBalance');
        getCardBalance({cardID: this.props.cardID, eid: this.props.eventData.eventid}).then(result => { 
            if(result.data.state === 1){this.setState({updating: 'updated', userData: result.data.data})}
            else{this.setState({updating: 'updated'})}
        }).catch(e => {console.log(e); this.setState({updating: 'offline'})});
    }

    formatDate(tijd, type, dagen){
        if(type === 'event'){
            var date = new Date(0);
            date.setUTCSeconds(tijd);
            var maand = ' ' + language.months[date.getMonth()] + ' ';
            if(dagen && dagen > 1){
                var date2  = new Date(date);
                date2.setDate(date.getDate() + (dagen -1))
                if(date.getMonth() === date2.getMonth()){
                    maand = ' ' + language.tm + ' ' + date2.getDate() + ' ' + language.months[date.getMonth()] + ' ';
                }
                else{
                    maand = ' ' + language.months[date.getMonth()] + ' ' + language.tm + ' ' + date2.getDate() + ' ' + language.months[date2.getMonth()] + ' ';
                }
            }		
            return '' + date.getDate() + maand + date.getFullYear();
        }
        return language.eventlocation;
    }

    openTimetable(){
        if(this.props.eventData.timetable && this.props.eventData.timetable.length > 0){
            window.location.href = this.props.eventData.timetable[0].img;
        }
        else{alert(language.timetableunavailable)}
    }

    openFestivalmap(){
         if(this.props.eventData.festivalmap && this.props.eventData.festivalmap !== ''){
            window.location.href = this.props.eventData.festivalmap;
         }
         else{alert(language.mapunavailable)}
    }

    openLockers(){
        alert(language.lockersunavailable)
    }

    openWhatsapp(){
        window.location.href = 'whatsapp://send?phone=+31854011114+&text=My account number is ' + this.props.cardID + '---' + this.state.userData.id + ' and my question is: ';
    }

    changeEventState = (state) => {this.setState({eventState: state});}
    searchParams = new URL(window.location.href).searchParams;
  
    
    }
    
    export default EventContainer;